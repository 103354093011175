<!-- 重写order/new_index.vue -->
<template>
	<div class='tab_info'>
		<div class="table_before">
			<div class="left">
			</div>
			<div class="right_btn">
				<a-button type="primary" class="btn_item" @click="visible = true">
					新增品相分组
				</a-button>
			</div>
		</div>
		<a-table key='id' :expandedRowRender='false'  :columns="columns" :slot_table_list="['operation','status']" :pagination="false" ref="list"
			:dataSource='data_list'>
			<template slot="status"  slot-scope="text,record,index">
			    <span v-if="record.status==1">启用</span>
			    <span v-else-if="record.status==0">禁用</span>
			</template>
			<template slot="operation" slot-scope="text,record,index">
				<a class='del_iocn' @click="add_item(record)">新增品相</a>
				<a class='del_iocn' :style="record.status?'color:red;':'color:#00A3FF;'" @click="change_item(record)">{{record.status?'禁用':'启用'}}</a>
				<a class='del_iocn' @click="edit_group(record)">编辑</a>
				<span>
				    <a-popconfirm title="您确定要删除吗?" v-auth="'delete'" @confirm="del(record)">
				        <a  class='del_iocn'>删除</a>
				    </a-popconfirm>
				</span>
			</template>
			<template slot="expandedRowRender" slot-scope="data">
				<a-table key='id'  class='tab_info' :columns="columns_sub" :slot_table_list="['operation']" :pagination="false"
					:dataSource="data.children">
					<template slot='operation' slot-scope="text,record,index">
						<a class='del_iocn' @click="edit_item(record)">编辑</a>
				    <a-popconfirm title="您确定要删除吗?" v-auth="'delete'" @confirm="del_item(record)">
				       <a class='del_iocn' >删除</a>	
				    </a-popconfirm>
				</span>
					</template>
				</a-table>
			</template>
		</a-table>
		
		<EditPop @handleSubmit="submit" :form_data="form_data" :visible.sync="visible">
			
		</EditPop>
		
		
		<EditPop @handleSubmit="submit2" :form_data="form_data2" :visible.sync="visible2">
			
		</EditPop>
	</div>
</template>
<script>

	import {
		itemGroupList,
		modifyItemGroup,
		changeItemGroup,
		delItemGroup,
		modifyItem,delItem
	} from "@/api/cost";
	import Form from "@/components/Form";
	import EditPop from "@/components/EditPop";
	import { getGoodsTitleV2 } from "@/api/goods";
	
	import {
		getGoods,
		delOrder
	} from '@/api/cost'
	export default {
		name: "Index",
		components: {
			EditPop,
			Form
		},
		data() {
			return {
				visible:false,
				visible2:false,
				form_data: {
				    title: '添加',
				    list: [
				        {
				            type: 'text',
				            name: 'id',
				            hidden: true,
				            options: {},
				        },
				        {
				            type: 'text',
				            name: 'name',
				            title: '品相分组名称',
				            options: {
								rules: [
								    {
								        required: true,
								        message: "请输入品相分组名称"
								    }
								]
				            },
						},
						
				    ]
				},
				form_data2: {
				    title: '添加',
				    list: [
				        {
				            type: 'text',
				            name: 'id',
				            hidden: true,
				            options: {},
				        },
						{
						    type: 'text',
						    name: 'group_id',
						    hidden: true,
						    options: {},
						},
				      {
				          type: "select",
				          name: "goods_id",
				          title: "选择品项",
				          mode: "default",
				          options: {
				              initialValue: '',
				              rules: [
				                  {
				                      required: true,
				                      message: "请选择品项"
				                  }
				              ],
				          },
				          list: []
				      },
						{
						    type: "tree-select",
						    name: "department_id",
						    title: "所属部门",
						    options: {
						    	rules: [
						    	    {
						    	        required: true,
						    	        message: "请选择所属部门"
						    	    }
						    	]
						    },
						    treeData: [],
						    multiple: true
						},
						
				    ]
				},
				itemGroupList,

				order_set_visible: false, // 订单设置弹窗是否显示
				tab_index: 0,
				columns: [{
						title: "品项分组名称",
						dataIndex: "name",
					},
					{
						title: "状态",
						dataIndex: "status",
						key: 'status',
						scopedSlots: {
							customRender: 'status'
						},
					},
					// 
					{

						align: 'right',
						title: "操作",
						dataIndex: "operation",
						key: 'operation',
						scopedSlots: {
							customRender: 'operation'
						},
					},
				],
				columns_sub: [{
						title: "品相名称",
						dataIndex: "goods_title",
					},
					{
						title: "适用部门",
						dataIndex: "department_name",
					},
					{
						align: 'right',
						title: "操作",
						dataIndex: "operation",
						key: 'operation',
						scopedSlots: {
							customRender: 'operation'
						},
					},
				],
				data_list: [],
				prev_expand_row: [], // 此处记录上一次展开的子表格ID
			};
		},
		async created() {
			// 缓存该页面
			this.$keep_route.add(this.$options.name);
			this.get_department(); // 获取部门树（编辑/添加时的所属区域）
			this.getGoods()//获取可选择品项
			this.get_list()
		},
		methods: {
			getGoods() {
			    getGoodsTitleV2().then(res=>{
			        this.form_data2.list.forEach(item => {
			        if (item.name == "goods_id") {
			                item.list =  res.data.list
			            }
			        });
			    })
			    
			},
			change_item(data){
				changeItemGroup({data:{
					id:data.id
				},info:true}).then(res=>{
					this.get_list()
				})
			},
			edit_group(data){
				this.form_data.list.forEach(item => {
				if (item.name == "id") {
				       item.options.initialValue=data.id
				    }
					if (item.name == "name") {
					       item.options.initialValue=data.name
					    }
				});
				this.visible=true
			},
			edit_item(data){
				this.form_data2.list.forEach(item => {
				if (item.name == "id") {
				       item.options.initialValue=data.id
				    }
					if (item.name == "group_id") {
					       item.options.initialValue=data.tree_pid
					    }
					if (item.name == "department_id") {
					       item.options.initialValue=data.department_id.split(',')
					    }
				});
				this.visible2=true
			},
			add_item(data){
				this.form_data2.list.forEach(item => {
					if (item.name == "group_id") {
					       item.options.initialValue=data.id
					    }
				
				});
				this.visible2=true
			},
			// 获取部门树形数据
			get_department(){
				this.$method.get_department().then(res=>{
					this.$method.set_form_list(this.form_data2.list,'department_id','treeData',res);
				})
			},
			 // 新增品项 
			submit2(e){
				let data = e.values;
				if(data.department_id && data.department_id.length){
					data.department_id = data.department_id.toString();
				}
				modifyItem({data:{
					 ...data,
				},info:true
				}).then(res=>{
					this.visible2=false
					this.group_id =''
					this.get_list()
				})
			},
			// 新增品相分组
			submit(e){
				  let data = e.values;
				modifyItemGroup({data:{
					 ...data
				},info: true,}).then(res=>{
					this.visible=false
					this.get_list()
				})
			},
			
			start_info(info){
				changeItemGroup().then(res=>{
					
				})
			},
			del(record) {
			    delItemGroup({
			        data: {
			            id: record.id
			        },
			        info: true,
			    }).then(res => {
			      this.get_list()
			    })
			},
			// 
			del_item(record) {
			    delItem({
			        data: {
			           goods_id:record.goods_id,
						group_id:record.group_id   
			        },
			        info: true,
			    }).then(res => {
			      this.get_list()
			    })
			},
			// 查看订单详情
			go_info(data) {
				this.$router.push({
					path: 'order_details',
					query: {
						id: data.id
					}
				})
			},
			get_list() {
				itemGroupList().then(res => {
					console.log('res', res)
					this.data_list = res.data.list
				})
			},
			// 列表子表格展开
			handleExpandedRowsChange(event) {
				let now_row_id = null; // 当前行的ID
				if (!this.prev_expand_row.length && event.length) {
					now_row_id = event[0];
				} else {
					if (event.length > this.prev_expand_row.length) {
						now_row_id = event[event.length - 1];
					}
				}
				this.prev_expand_row = event; // 在本轮判断结束后记录本次操作展开的列表ID，于下次展开时判断当前行ID
				let data = {};
				this.tab_table_list[this.tab_index].sub_api().then(res => {
					let list = [];
					this.$refs.list.list.forEach((item, index) => {
						if (item[this.tab_table_list[this.tab_index].key] == now_row_id) {
							item.list = res.data.list;
						}
						list.push(item)
					})
					this.$refs.list.set_data('list', list)
				})
			},

			go_edit(data) {
				this.$router.push({
					path: 'order_edit',
					query: {
						id: data.id
					}
				})
			}

		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");

	.tab_info {
		margin: 20px 16px;
		background: #fff;
		padding-top: 20px;
		padding-left: 16px;
	}

	.table_before {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;

		.left {
			position: relative;
			bottom: -1px;
		}
	}

	.ant-tabs-bar {
		margin: 0 !important;
	}

	.btn_item {
		margin-right: 10px;
	}

	.push_item {
		margin-left: 115px;
		height: auto;
		margin-top: -20px;

		// line-height: 40px;
		.lable_text {
			width: 70px;
			display: inline-block;
		}

		display: flex;

		.good_item {
			height: 70px;

			p {
				height: 20px;
			}
		}
	}

	.cont_box {
		align-items: center;
		display: flex;

		.cont_item {
			width: 120px;

			p {
				height: 22px;
				line-height: 22px;
			}
		}



	}

	.del_iocn {
		margin-left: 35px;
		color: #00A3FF;
	}

	.add_man {
		cursor: pointer;
		color: #00A3FF;
	}
</style>